import { Meta, Title } from '@angular/platform-browser';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Scroll, Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { Subject, combineLatest, Subscription, ReplaySubject, firstValueFrom } from 'rxjs';
import { ViewportScroller, DOCUMENT } from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { Page, LocalLink, isPageWithCountries, isPreviewPage, PageType, LanguageUrls } from '../types/page';
import { BaseLocationService } from './base-location.service';
import { Graph, Thing, WithContext, SearchAction, WebSite, Organization, Article, Person } from 'schema-dts';
import { SettingsService } from './settings.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const NAVIGATION_STATE_PARAMETER_SCROLL_TO = 'scrollTo';

export function preventScroll(navigationExtras: NavigationExtras, platformBrowser: boolean): NavigationExtras {
  navigationExtras.state = {
    ...navigationExtras.state,
    [NAVIGATION_STATE_PARAMETER_SCROLL_TO]: (platformBrowser ? [window.scrollX, window.scrollY] : undefined),
  };
  return navigationExtras;
}

@Injectable({
  providedIn: 'root',
})
export class PageService implements OnDestroy {

  private meta = inject(Meta);
  private title = inject(Title);
  private router = inject(Router);
  private viewportScroller = inject(ViewportScroller);
  private baseLocation = inject(BaseLocationService);
  private document = inject(DOCUMENT);

  public dynamicSectionsRendered = new ReplaySubject<void>(1);

  private languageUrlsSubject = new ReplaySubject<LanguageUrls>(1);
  public readonly languageUrls = this.languageUrlsSubject.asObservable();

  private pageSubject = new ReplaySubject<Page>(1);
  public readonly page = this.pageSubject.asObservable();

  private titleSubject = new Subject<string>();

  private localContentLinkSubject = new Subject<LocalLink | null>();
  private isLocalContentSubject = new Subject<boolean>();
  public readonly localContentLink = this.localContentLinkSubject.asObservable();

  private specialPageRouteSubscription?: Subscription;

  private readonly loadingSubject$ = new ReplaySubject<boolean>(1);
  public readonly loading$ = this.loadingSubject$.asObservable();

  private settings = inject(SettingsService);

  public init() {
    this.viewportScroller.setOffset([0, 160]);

    // Scroll to anchors, once the page is loaded and the route is a hash link
    combineLatest([
      this.dynamicSectionsRendered,
      this.router.events.pipe(
        filter((event): event is Scroll => event instanceof Scroll),
      ),
      this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map(() => this.router.getCurrentNavigation()),
      ),
    ]).pipe(
      takeUntilDestroyed(),
    ).subscribe(([, scrollEvent, currentNavigation]) => {
      const scrollTo: [number, number] = currentNavigation?.extras.state?.[NAVIGATION_STATE_PARAMETER_SCROLL_TO];
      if (scrollTo) {
        queueMicrotask(() => {
          this.viewportScroller.scrollToPosition(scrollTo);
        });
        return;
      }

      if ( scrollEvent.anchor !== null ) {
        this.viewportScroller.scrollToAnchor(scrollEvent.anchor);
      }
    });

    // Update the title of the page if it changes
    combineLatest([this.titleSubject, this.settings.titleSuffix]).pipe(
      takeUntilDestroyed(),
    ).subscribe(([newTitle, titleSuffix]) => {
      this.title.setTitle(newTitle + titleSuffix);
    });

    this.settings.siteName.pipe(
      takeUntilDestroyed(),
    ).subscribe(siteName => {
      this.meta.updateTag({property: 'og:site_name', content: siteName});
    });
  }

  public ngOnDestroy() {
    this.pageSubject.complete();
    this.titleSubject.complete();
    if (this.specialPageRouteSubscription !== undefined) {
      this.specialPageRouteSubscription.unsubscribe();
    }
  }

  public async setSpecialPage() {
    this.specialPageRouteSubscription = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    ).subscribe(async (event) => {
      const specialPageLanguagUrls: { [languageCode: string]: string } = {};
      const path = new URL(event.urlAfterRedirects, 'https://fakebase').pathname;
      for (const languageCode of await firstValueFrom(this.settings.availableLanguages)) {
        specialPageLanguagUrls[languageCode] = `${languageCode  }/${  path.split('/').splice(2).join('/')}`;
      }
      this.languageUrlsSubject.next(specialPageLanguagUrls);
    });
  }

  public deactivateSpecialPage() {
    if (this.specialPageRouteSubscription !== undefined) {
      this.specialPageRouteSubscription.unsubscribe();
    }
  }

  public setLoading(): void {
    this.loadingSubject$.next(true);
  }

  public async updatePage(page: Page): Promise<void> {
    if (isPreviewPage(page)) {
      this.settings.setGlobalSettingsUrl(page.globalSettingsUrl);
    }

    // Update page is never executed on special pages
    this.deactivateSpecialPage();

    if (isPageWithCountries(page)) {
      this.settings.setRegionCode(page.currentCountry);
      if (page.localContentLink && page.localContentLink.label) {
        this.localContentLinkSubject.next(page.localContentLink);
      } else {
        this.localContentLinkSubject.next(null);
      }
      this.isLocalContentSubject.next(page.isLocalContentPage);
    } else {
      this.settings.setRegionCode(null);
      this.languageUrlsSubject.next(page.languages);
    }

    await this.updateMetadata(page);

    this.pageSubject.next(page);

    this.loadingSubject$.next(false);
  }

  private addHreflangMetaTag(locale: string, url: string): void {
    const linkElement = this.document.createElement('link');
    linkElement.setAttribute('rel', 'alternate');
    linkElement.setAttribute('hreflang', locale);
    linkElement.setAttribute('href', `${this.baseLocation.origin}${url}`);
    this.document.head.appendChild(linkElement);
  }

  private addAlternateMetaTag(language: string, url: string, region?: string): void {
    this.addHreflangMetaTag(region ? `${language.toLowerCase()}-${region.toLowerCase()}` : language.toLowerCase(), url);
    this.meta.addTag({property: 'og:locale:alternate', content: region ? `${language.toLowerCase()}_${region.toUpperCase()}` : language.toLowerCase()});
  }

  private async updateMetadata(page: Page) {
    const jsonLdThings: Thing[] = [];
    // TODO: find a way on special pages to have metadata?

    this.meta.updateTag({property: 'og:type', content: page.type});

    // Set the title
    this.titleSubject.next(page.title);
    this.meta.updateTag({property: 'og:title', content: page.title});

    // Set the description
    if (page.description) {
      this.meta.updateTag({name: 'description', content: page.description});
      this.meta.updateTag({property: 'og:description', content: page.description});
    } else {
      this.meta.removeTag('name="description"');
      this.meta.removeTag('property="og:description"');
    }

    this.meta.updateTag({
      name: 'robots',
      content: page.excludeFromSearch ?  'noindex, follow' : 'index, follow',
    });

    if (page.type === PageType.Article) {
      this.meta.updateTag({property: 'og:published_time', content: page.publishedDate});
      this.meta.updateTag({property: 'og:modified_time', content: page.modifiedDate});

      // https://developers.google.com/search/docs/appearance/structured-data/article
      // TODO: image
      const article: Article = {
        '@type': 'Article',
        'datePublished': page.publishedDate,
        'dateModified': page.modifiedDate,
      }

      if (page.authors !== undefined && page.authors.length > 0) {
        article.author = page.authors.map(author => ({
          '@type': 'Person',
          ...author,
        } satisfies Person));
      }

      if (page.headline) {
        article.headline = page.headline;
      }

      jsonLdThings.push(article);
    } else {
      this.meta.removeTag('property="og:published_time"');
      this.meta.removeTag('property="og:modified_time"');
    }

    // Set the language
    if (isPageWithCountries(page)) {
      this.meta.updateTag({property: 'og:locale', content: `${page.currentLanguage.toLowerCase()}_${page.currentCountry.toUpperCase()}`});
    } else {
      this.meta.updateTag({property: 'og:locale', content: page.currentLanguage.toLowerCase()});
    }

    // Delete old canonical link
    const oldCanonicalLinkElements = this.document.head.querySelectorAll<HTMLLinkElement>('link[rel="canonical"]');
    for (const oldCanonicalLinkElement of oldCanonicalLinkElements) {
      oldCanonicalLinkElement.remove();
    }

    // Set the canonical link
    const canonicalLinkElement = this.document.createElement('link');
    canonicalLinkElement.setAttribute('rel', 'canonical');
    canonicalLinkElement.setAttribute('href', page.canonical);
    this.document.head.appendChild(canonicalLinkElement);
    const pageUrl = this.baseLocation.origin + page.path;
    this.meta.updateTag({property: 'og:url', content: pageUrl}); // not 100% sure if this should not rather be the canonical

    // Delete all old <link rel="alternate" hreflang="" href="">
    const alternatePages = this.document.head.querySelectorAll<HTMLLinkElement>('link[rel="alternate"][hreflang]');
    for (const alternatePage of alternatePages) {
      alternatePage.remove();
    }
    // Delete all old <meta property="og:locale:alternate" content="">
    this.meta.removeTag('property="og:locale:alternate"');

    // Add new <link rel="alternate" hreflang="" href="">
    const fallbackLanguage = await firstValueFrom(this.settings.fallbackLanguage);
    if (isPageWithCountries(page)) {
      const fallbackCountry = await firstValueFrom(this.settings.fallbackCountry);
      for (const [country, languages] of Object.entries(page.countryLanguageCombinations)) {
        for (const [language, variantUrl] of Object.entries(languages)) {
            this.addAlternateMetaTag(language, variantUrl, country);
            if (language === fallbackLanguage && country === fallbackCountry) {
              this.addHreflangMetaTag('x-default', variantUrl);
            }
        }
      }
    } else {
      for (const [language, variantUrl] of Object.entries(page.languages)) {
          this.addAlternateMetaTag(language, variantUrl);
          if (language === fallbackLanguage) {
            this.addHreflangMetaTag('x-default', variantUrl);
          }
      }
    }

    if (await firstValueFrom(this.settings.homeUrl) === page.path) {
      type QueryAction = SearchAction & {
        'query-input': string;
      };
      type WebSiteSearchAction = WebSite & {
        potentialAction: QueryAction;
      };

      const action: QueryAction = {
        '@type': 'SearchAction',
        'target': {
          '@type': 'EntryPoint',
          'urlTemplate': `${this.baseLocation.origin}/search/?query={search_term_string}`,
        },
        'query-input': 'required name=search_term_string',
      }

      // https://developers.google.com/search/docs/appearance/structured-data/sitelinks-searchbox
      jsonLdThings.push({
        '@type': 'WebSite',
        'url': `${this.baseLocation.origin}`,
        'potentialAction': action,
      } satisfies WebSiteSearchAction);

      // https://developers.google.com/search/docs/appearance/structured-data/logo
      const logoUrl = (new URL(
        await firstValueFrom(this.settings.logo),
        this.baseLocation.origin,
      )).toString();
      jsonLdThings.push({
        '@type': 'Organization',
        'url': `${this.baseLocation.origin}`,
        'logo': logoUrl,
      } satisfies Organization);
    }

    const jsonLd: Graph = {
      '@context': 'https://schema.org',
      '@graph': jsonLdThings,
    };

    // Delete old json-ld elements
    const oldJsonLdElements = this.document.head.querySelectorAll<HTMLScriptElement>('script[type="application/ld+json"]:not([static])');
    for (const oldElement of oldJsonLdElements) {
      oldElement.remove();
    }
    this.addJsonLd(jsonLd);
  }

  public addJsonLd<T extends Thing>(jsonLd: Graph | WithContext<T>, isStatic = false): void {
    const scriptElement = this.document.createElement('script');
    scriptElement.setAttribute('type', 'application/ld+json');
    if (isStatic === true) {
      scriptElement.setAttribute('static', '');
    }
    scriptElement.innerHTML = JSON.stringify(jsonLd);
    this.document.head.appendChild(scriptElement);
  }

}
