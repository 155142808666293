import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';

let lastLocale = '';
export const localeReloadGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  if (!isPlatformBrowser(inject(PLATFORM_ID))) {
    return true;
  }

  const urlLocale = state.url.split('/')[1];

  if (urlLocale.length !== 2) {
    return true;
  }

  if (urlLocale === lastLocale) {
    return true;
  }

  const lastLocaleEmpty = lastLocale === '';
  lastLocale = urlLocale;
  if (lastLocaleEmpty) {
    return true;
  }

  window.location.href = state.url;
  return false;
}
