import { Injectable, InjectionToken, PLATFORM_ID, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { BaseLocationService } from './base-location.service';
import { notFoundPath } from '../app.routes';
import { Router } from '@angular/router';
import { RESPONSE } from '@knorr-bremse-portals/ngx-components';

export type UrlTransformers = {
  searchRegex: RegExp;
  replaceValue: string;
}[];

export interface ContentRepositoryServiceConfigurationInterface {
  urlTransformers: UrlTransformers;
  serverUrlTransformers?: UrlTransformers;
}

export const contentRepositoryServiceConfiguration = new InjectionToken<ContentRepositoryServiceConfigurationInterface>('contentRepositoryServiceConfiguration');

@Injectable({
  providedIn: 'root',
})
export class ContentRepositoryService {

  private configuration = inject(contentRepositoryServiceConfiguration);
  private http = inject(HttpClient);
  private baseLocationService = inject(BaseLocationService);
  private platformId = inject(PLATFORM_ID);
  private readonly urlTransformers = this.configuration.serverUrlTransformers && isPlatformServer(this.platformId) ? this.configuration.serverUrlTransformers : this.configuration.urlTransformers;

  private response = inject(RESPONSE, {
    optional: true,
  });

  private replaceVariables(url: string): string { // TODO: check if this can be abused on the server
    return url
      .replace('${location.host}', this.baseLocationService.host)
      .replace('${location.hostname}', this.baseLocationService.hostname)
      .replace('${location.port}', this.baseLocationService.port)
      .replace('${location.protocol}', this.baseLocationService.protocol)
      .replace('${location.origin}', this.baseLocationService.origin)
    ;
  }

  private applyTransformers(url: string): string {
    this.urlTransformers.forEach((urlTransformer) => {
      url = url.replace(urlTransformer.searchRegex, urlTransformer.replaceValue)
    });
    return url;
  }

  public fetch<T>(url: string): Observable<T> {
    url = this.applyTransformers(url);
    url = this.replaceVariables(url);
    return this.http.get<T>(url, {
      withCredentials: true,
    });
  }

  public handleError(error: unknown, locale: string, region?: string, expectNotFound = false): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      return this.handleHttpError(error, locale, region, expectNotFound);
    } else {
      this.handleUnknownError(error);
    }
    throw error;
  }

  public handleUnknownError(error: unknown): Observable<never> {
    if (isPlatformServer(this.platformId) && this.response) {
      this.response.status(500);
      this.response.end();
    }
    throw error;
  }

  private router = inject(Router);

  public handleHttpError(error: HttpErrorResponse, locale: string, region?: string, expectNotFound = false): Observable<never> {
    const statusCode = error.status === 404 && expectNotFound === false ? 500 : error.status;
    this.response?.status(statusCode);

    switch(statusCode) {
      case 404:
        this.navigateToErrorPage(notFoundPath, locale, region);
        break;
      default:
        this.response?.end();
        break;
    }

    return EMPTY; // error is logged with http-logger.interceptor
  }

  private navigateToErrorPage(errorPage: string, locale: string, region?: string, error?: unknown): void {
    const errorUrl = [locale];
    if (region) {
      errorUrl.push(region);
    }
    errorUrl.push(errorPage);
    this.router.navigate(errorUrl, {
      skipLocationChange: true,
      state: error ? {
        error: error,
      } : undefined,
    });
  }

}
