import { Injectable, inject } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserLocaleService, Locale } from '@knorr-bremse-portals/ngx-components/services/user-locale';
import { GLOBAL_REGION } from './settings-constants';
import { CountryLanguages, CountryLanguageUrls, SettingsService } from './settings.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {

  private userLocaleService = inject(UserLocaleService);
  private settings = inject(SettingsService);
  private logger = inject(LoggerService);

  public readonly optimalStartPageLocale = combineLatest([
    this.settings.startPageCountryLanguages,
    this.settings.fallbackCountry,
    this.settings.fallbackLanguage,
  ]).pipe(
    map(([startPageCountryLanguages, fallbackRegion, fallbackLanguage]) => {
      return this.getOptimalLocale(startPageCountryLanguages, fallbackLanguage, fallbackRegion);
    }),
  );

  public getOptimalLocale(availableCountryLanguages: CountryLanguages, fallbackLanguage: string, fallbackRegion?: string): Locale {
    const userLocales = this.userLocaleService.getUserLocales();

    this.logger.renderer.debug('Determined user locale', { locales: userLocales });

    if (availableCountryLanguages[GLOBAL_REGION]) {
      return {
        language: this.getOptimalLanguage(availableCountryLanguages[GLOBAL_REGION], fallbackLanguage),
        region: GLOBAL_REGION,
      };
    }

    for(const userLocale of userLocales) {
      if (availableCountryLanguages[userLocale.region]) {
        return {
          language: this.getOptimalLanguage(availableCountryLanguages[userLocale.region], fallbackLanguage),
          region: userLocale.region,
        };
      }
    }

    if (fallbackRegion && availableCountryLanguages[fallbackRegion]) {
      return {
        language: this.getOptimalLanguage(availableCountryLanguages[fallbackRegion], fallbackLanguage),
        region: fallbackRegion,
      };
    } else {
      const firstAvailableRegion = Object.keys(availableCountryLanguages)[0];
      return {
        language: this.getOptimalLanguage(availableCountryLanguages[firstAvailableRegion], fallbackLanguage),
        region: firstAvailableRegion,
      };
    }
  }

  public getOptimalLanguage(availableLanguages: string[], fallbackLanguage: string): string {
    availableLanguages = availableLanguages.map((language) => language.toLowerCase()); // This is obsolete once FirstSpirit generated the languages in lowercase, already fixed in templates

    const userLocales = this.userLocaleService.getUserLocales();
    const userLanguages = userLocales.map(locale => locale.language);

    for(const userLanguage of userLanguages) {
      if (availableLanguages.includes(userLanguage)) {
        return userLanguage;
      }
    }

    if (availableLanguages.includes(fallbackLanguage)) {
      return fallbackLanguage;
    } else {
      return availableLanguages[0];
    }
  }

  public convertCountryLanguageUrlsToCountryLanguages(countryLanguageUrls: CountryLanguageUrls): CountryLanguages {
    const availableCountryLanguages: CountryLanguages = {};
    for (const [country, countryLanguages] of Object.entries(countryLanguageUrls)) {
      availableCountryLanguages[country] = Object.keys(countryLanguages);
    }
    return availableCountryLanguages;
  }

}
