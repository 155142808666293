import { Routes } from '@angular/router';
import { DynamicPageResolver } from './providers/dynamic-page.resolver';
import { localeRedirectGuard } from './providers/locale-redirect.guard';
import { localeReloadGuard } from './providers/locale-reload.guard';

export const standaloneShellPathPart = 'standalone-shell';
export const notFoundPath = 'not-found';
export const forbiddenPath = 'forbidden';
export const unreachablePath = 'unreachable';
export const unknownErrorPath = 'unknown-error';

export const routes: Routes = [
  { path: '', pathMatch: 'full', canActivate: [localeRedirectGuard], children: []},
  { path: 'search', canActivate: [localeRedirectGuard], children: []},
  { path: '', canActivateChild: [localeReloadGuard],
    children: [
      { path: `:language/${standaloneShellPathPart}/:type`, loadComponent: () => import('./components/standalone-shell/standalone-shell.component').then(comp => comp.StandaloneShellComponent) },
      { path: `:language/:region/${standaloneShellPathPart}/:type`, loadComponent: () => import('./components/standalone-shell/standalone-shell.component').then(comp => comp.StandaloneShellComponent) },
      { path: '', loadComponent: () => import('./components/page-shell/page-shell.component').then(comp => comp.PageShellComponent),
        children: [
          { path: ':language/search', loadComponent: () => import('./components/global-search/global-search.component').then(comp => comp.GlobalSearchComponent) },
          { path: ':language/:region/search', loadComponent: () => import('./components/global-search/global-search.component').then(comp => comp.GlobalSearchComponent) },
          { path: `:language/${notFoundPath}`, loadComponent: () => import('./pages/not-found-page/not-found-page.component').then(comp => comp.NotFoundPageComponent) },
          { path: `:language/:region/${notFoundPath}`, loadComponent: () => import('./pages/not-found-page/not-found-page.component').then(comp => comp.NotFoundPageComponent) },
          { path: '**', resolve: { page: DynamicPageResolver }, loadComponent: () => import('./components/dynamic-page/dynamic-page.component').then(comp => comp.DynamicPageComponent) },
        ],
      },
    ],
  },
];
